var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{attrs:{"bottom":0,"showRemarks":false}},[_c('a-tabs',{attrs:{"tabBarStyle":{
                  margin: '0',
                }},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l(([{
          name: '我的课程',
          value: 'course',
      }, {
          name: '我的考试',
          value: 'test'
      }]),function(item){return _c('a-tab-pane',{key:item.value,attrs:{"tab":item.name}})}),1)],1),(_vm.active === 'course')?_c('a-card',{staticClass:"container"},[_c('a-form-model',{attrs:{"colon":false,"model":_vm.form,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"课程名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"学习情况"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l(([ {
                        name: '未完成',
                  value: 0
               
                }, {
                      name: '已完成',
                  value: 1,
                }]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowKey":"id"},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"课程名称","data-index":"courseName"}}),_c('a-table-column',{attrs:{"title":"总学时(分钟)","data-index":"courseStudyDuration","width":"100px","align":"center"}}),_c('a-table-column',{attrs:{"title":"学习进度","align":"center","width":"280px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('div',{staticStyle:{"width":"240px"}},[_c('a-progress',{attrs:{"percent":text.courseProgress}})],1)]}}],null,false,897807245)}),_c('a-table-column',{attrs:{"title":"操作","align":"center","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.open(text)}}},[_vm._v(_vm._s(text.courseProgress >= 100 ? '详情' : '继续学习'))])])]}}],null,false,1951173101)})],1)],1):_vm._e(),(_vm.active === 'test')?_c('Exam'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
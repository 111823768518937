import { mapState, mapActions } from 'vuex'

export default {


    computed: {
        ...mapState('study', ['directoryLoading', 'rawList', 'tree', 'useableTree']),
    },

    mounted() {
        if (this.rawList.length === 0) {
            this.getDirectory();
        }
    },

    methods: {
        ...mapActions("study", ["getDirectory"]),

        findFather(result, id) {
            const res = this.rawList.find((item) => item.id === id);
            if (res && res.pid) {
              result.unshift(res.pid);
              this.findFather(result, res.pid);
            }
          },

                  findFatherNames(result, id) {
            const res = this.rawList.find((item) => item.id === id);
            if (res) {
                        result.unshift(res.name);

                               if (res.pid) {
    
              this.findFatherNames(result, res.pid);
            }
            }
     
          },
    }
}
import request from '../request'

export function fetchList(params) {
    return request({
        url: '/office-service/exam/user/main/list',
        params,
    })
}

export function remove(data) {
    return request({
        url: '/office-service/exam/admin/delete',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/office-service/exam/admin/add',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/office-service/exam/admin/update',
        method: 'post',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/office-service/exam/user/main/query/' + params.id,
    })
}

export function startExam(data) {
    return request({
        url: '/office-service/exam/user/startExam',
        method: 'post',
        data,
    })
}

// 答题
export function answer(data) {
    return request({
        url: '/office-service/exam/user/submit',
        method: 'post',
        data
    })
}

// 获取用户填写的答案
export function fetchAnswer(params) {
    return request({
        url: "/office-service/exam/user/result/query/" + params.id
    })
}

export function fetchUserAnswerList(params) {
    return request({
        url: '/office-service/exam/admin/list',
        params,
    })
}

// 获取用户分数
export function fetchUserAnswer(params) {
    return request({
        url: '/office-service/exam/admin/result/query',
        params
    })
}

// 打分
export function mark(data) {
    return request({
        url: '/office-service/exam/admin/mark',
        method: 'post',
        data,
    })
}


<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        :tabBarStyle="{
                    margin: '0',
                  }"
        v-model="active"
      >
        <a-tab-pane
          v-for="item in [{
            name: '我的课程',
            value: 'course',
        }, {
            name: '我的考试',
            value: 'test'
        }]"
          :key="item.value"
          :tab="item.name"
        ></a-tab-pane>
      </a-tabs>
    </Pane>

    <a-card class="container" v-if="active === 'course'">
      <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="课程名称" style="width: 150px"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select v-model="form.status" placeholder="学习情况" style="width: 150px">
            <a-select-option
              v-for="item in [ {
                          name: '未完成',
                    value: 0
                 
                  }, {
                        name: '已完成',
                    value: 1,
                  }]"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button @click="query" type="primary">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="课程名称" data-index="courseName"></a-table-column>

        <a-table-column
          title="总学时(分钟)"
          data-index="courseStudyDuration"
          width="100px"
          align="center"
        ></a-table-column>

        <a-table-column title="学习进度" align="center" width="280px">
          <template slot-scope="text">
            <div style="width: 240px">
              <a-progress :percent="text.courseProgress" />
            </div>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                @click.prevent="open(text)"
              >{{ text.courseProgress >= 100 ? '详情' : '继续学习' }}</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <Exam v-if="active === 'test'" />
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import Exam from "./components/exam.vue";

function fetchList(data) {
  return request({
    url: "/office-service/study/user/course/list",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark],

  components: {
    Exam
  },

  data() {
    return {
      active: "course",
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    open(text) {
      this.$router.push(`/knowledge/study/detail?courseId=${text.courseId}`);
    }
  }
};
</script>
